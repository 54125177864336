import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  selectRoot: {
    marginTop: rem(12),
    marginBottom: rem(12),
  },
  selectInput: {
    borderColor: "#CCCCCC",
    color: "rgba(0, 0, 0, 0.6)",
    borderRadius: 10,
    ":focus-within": {
      borderColor: "#CCCCCC",
    },
  },
  selectItem: {
    "&[data-hovered]": {
      backgroundColor: "#fa95a0",
    },
    "&[data-selected]": {
      ":hover": {
        backgroundColor: "#f87180",
      },
      backgroundColor: "#f87180",
    },
  },
  selectLabel: {
    fontSize: theme.fontSizes.md,
  },
  selectOptionLocation: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: theme.fontSizes.xl,
  },
  selectOptionLocationSub: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: theme.fontSizes.md,
  },
  selectOptionDuration: {
    marginLeft: rem(12),
  },
  submit: {
    backgroundColor: "#F64E60",
    ":hover": {
      backgroundColor: "#F64E60",
      opacity: 0.9,
    },
    transition: "all .2s ease-in-out",
    width: "100%",
    borderRadius: 10,
  },
  submitInner: {
    // width: rem(400),
    backgroundColor: "yellow"
  },
  submitText: {
    marginLeft: "auto",
  },
  submitIcon: {
    marginLeft: "auto",
  },
  hourBtn: {
    borderRadius: 20,
    borderColor: "#F64E60",
    color: "#F64E60",
    width: rem(102),
  },
  hourBtnSelected: {
    borderRadius: 20,
    backgroundColor: "#F64E60",
    color: "#fff",
    ":hover": {
      backgroundColor: "#F64E60",
    },
    width: rem(102),
  },
  label: {
    display: "flex",
    flexDiretion: "row",
    marginTop: rem(6),
    marginBottom: rem(6),
  },
  chipBtn: {
    borderRadius: 20,
    borderColor: "#F64E60",
    color: "#F64E60",
  },
  chipBtnSelected: {
    borderRadius: 20,
    backgroundColor: "#F64E60",
    color: "#fff",
    ":hover": {
      backgroundColor: "#F64E60",
    },
  },
}));

export default useStyles;
