import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Button, Flex, Text, rem } from "@mantine/core";

import createPaymentIntent from "utils/createPaymentIntent";
import { BOOK_SERVICE_BODY, STRIPE_AC_ID, BOOKING_ID } from "constants";
import { step as eventStep, serviceStep } from "store/atoms";
import { ROUTE_NAMES } from "routes/names";

import EventBuyingSummary from "views/TicketBooking/EventBuyingSummary";
import EventInfoCard from "views/TicketBooking/EventInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import { Loader, ErrorAlert, UserDetails } from "components";
import { ArrowLeftIcon, CardIcon } from "assets/icon";

function CardPayment({ clientSecret }) {
  const reduxstate = useSelector((state) => state.State);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [, setServiceSelectedStep] = useAtom(serviceStep);
  const [, setEventSelectedStep] = useAtom(eventStep);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (clientSecret) {
      stripe.retrievePaymentIntent(clientSecret).then((res) => {
        switch (res.paymentIntent.status) {
          case "succeeded":
            // setMessage("Payment succeeded!");
            break;
          case "processing":
            // setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            // setMessage("Your payment was not successful, please try again.");
            break;
          default:
            // setMessage("Something went wrong.");
            break;
        }
      });
    }
  }, [stripe, elements, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsSubmitting(true);

    let return_url = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;

    switch (true) {
      case process.env.REACT_APP_ENVIRONMENT === "PRE_STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "PROD":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
        break;
      default:
        break;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        return_url: `${return_url}${ROUTE_NAMES.PAYMENT_CHECK}`,
      }
    );

    if (paymentIntent) {
      if (paymentIntent.status !== "succeeded") {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ErrorAlert
                error="Your payment was not successful. Please try again."
                serverError={false}
                onClose={() => {
                  onClose();
                  const eventName = reduxstate.eventData.eventName
                    .replace(/\s+/g, "-")
                    .toLowerCase();
                  const refId = reduxstate.eventData.referenceId;
                  setEventSelectedStep(1);

                  navigate(`/booking/${eventName}/${refId}`);
                }}
              />
            );
          },
        });
        return;
      }

      if (paymentIntent.status === "succeeded") {
        navigate(ROUTE_NAMES.PAYMENT_COMPLETE, {
          state: { paymentSuccess: true },
        });
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error.type === "card_error" || error.type === "validation_error") {
      setIsSubmitting(false);
      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({ onClose, error: error.message, serverError: false }),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      setIsSubmitting(false);
      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            onClose: () => {
              onClose();
              navigate(-1);
            },
            error: "An unexpected error occurred.",
            serverError: false,
          }),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  return (
    <div className="stripe-payment">
      <div className="stripe-container">
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="stripe-form">
            <div className="form-container">
              <CardElement
                options={{
                  classes: {
                    base: "card-element-base",
                    focus: "card-element-focus",
                  },
                }}
              />
            </div>
          </div>
          <Button
            fullWidth
            radius={10}
            bg="#f64e60"
            size="lg"
            fz="md"
            disabled={isSubmitting || !stripe || !elements}
            id="submit"
            style={{
              opacity: isSubmitting || !stripe || !elements ? 0.5 : 1,
              transition: "all 0.3s ease-in-out", // Added transition property
            }}
            sx={{
              "&:hover": {
                backgroundColor: "#f64e60",
                opacity: 0.7,
                transition: "all 0.3s ease-in-out", // Added transition property
              },
            }}
            loading={isSubmitting}
            type="submit"
          >
            Pay & Confirm booking
          </Button>
        </form>
      </div>
    </div>
  );
}

export default function ElementsWrapper() {
  const navigate = useNavigate();

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState();
  const [, setServiceStep] = useAtom(serviceStep);
  const [, setEventStep] = useAtom(eventStep);

  const reduxstate = useSelector((state) => state.State);
  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};

  useEffect(() => {
    const res = loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    );

    setStripePromise(res);
  }, [stripeAcId]);

  useEffect(() => {
    (async () => {
      // const body = {};

      // if (reduxstate.currentDataFor === "service") {
      //   body.serviceId = bookServiceBody.service_id;
      //   body.slotDuration = bookServiceBody.duration;
      //   body.priceType = bookServiceBody.priceType;
      // } else {
      //   body.eventScheduleId = reduxstate.id;

      //   const selectedEventPricing = reduxstate.selectedEventPricing
      //     .filter((item) => item.bookingQty && item.bookingQty > 0)
      //     .map(({ bookingQty, totalPrice, ticketType }) => ({
      //       bookingQty,
      //       totalPrice,
      //       ticketType,
      //     }));

      //   body.selectedEventPricing = selectedEventPricing;

      //   const selectedAddonsPricing =
      //     reduxstate.addonsPricingList.length > 0
      //       ? reduxstate.addonsPricingList
      //           .filter(
      //             (addon) => "bookingQty" in addon && addon.bookingQty > 0
      //           )
      //           .map((addon) => ({
      //             bookingQty: addon.bookingQty,
      //             totalPrice: addon.totalPrice,
      //             id: addon.id,
      //           }))
      //       : [];

      //   body.selectedAddonsPricing = selectedAddonsPricing;
      // }

      const body = {
        serviceId: bookServiceBody.service_id,
        slotDuration: bookServiceBody.duration,
        priceType: bookServiceBody.priceType,
        eventScheduleId: reduxstate.id,
        selectedEventPricing: reduxstate.selectedEventPricing,
      };

      const serviceBodyPayload = {
        ...bookServiceBody,
        bookingId: "",
        utcOffset: new Date().getTimezoneOffset(),
      };

      const { client_secret, referenceId } = await createPaymentIntent({
        amount: Number(reduxstate.totalPrice),
        body,
        paymentMethod: "creditCard",
        serviceBodyPayload,
      });

      window.localStorage.setItem(BOOKING_ID, referenceId);
      setClientSecret(client_secret);
    })();
  }, []);

  const handleChangePaymentMethod = () => {
    if (reduxstate.currentDataFor === "service") {
      setServiceStep(4);
      navigate(-1, { replace: true });
    } else {
      setEventStep(3);
      navigate(-1, { replace: true });
    }
  };

  if (!stripePromise) return null;

  return (
    <div>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <div className="ticket-services">
            <div className="wrapper">
              <div className="content">
                <div className="page-hdr">
                  <button className="back-btn" onClick={() => navigate(-1)}>
                    <ArrowLeftIcon />
                  </button>
                  <div className="title">Review & Pay</div>
                </div>
                {reduxstate.currentDataFor === "service" ? (
                  <>
                    <ServiceInfoCard
                      serviceData={reduxstate.serviceData}
                      isReadMore={false}
                      selectedStep={5}
                      toggleReadMore={() => {}}
                      when={{
                        day: bookServiceBody.slot_date,
                        start: bookServiceBody.slot_start_time,
                        end: bookServiceBody.slot_end_time,
                      }}
                      where={reduxstate.serviceSelectedLocation}
                    />
                    <UserDetails />
                    <ServiceBuyingSummary reduxstate={reduxstate} />
                  </>
                ) : (
                  <>
                    <EventInfoCard
                      reduxstate={reduxstate}
                      selectedStep={5}
                      isReadMore={false}
                      toggleReadMore={() => {}}
                    />
                    <UserDetails />
                    <EventBuyingSummary reduxstate={reduxstate} />
                  </>
                )}
                <div className="section">
                  <div className="block">
                    <Flex
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Flex direction="row" gap="sm" align="center">
                        <CardIcon w={rem(32)} h={rem(32)} />
                        <Text fw={700} fz="lg">
                          Card Pay
                        </Text>
                      </Flex>
                      <Button
                        variant="subtle"
                        onClick={handleChangePaymentMethod}
                        fz="md"
                        c="#584CF4"
                      >
                        Change
                      </Button>
                    </Flex>
                  </div>
                </div>
              </div>
            </div>
            <CardPayment clientSecret={clientSecret} />
          </div>
        </Elements>
      ) : (
        <div className="ticket-view-loading">
          <Loader size={30} thin={2} />
        </div>
      )}
    </div>
  );
}
