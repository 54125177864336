import React, { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

// route names
import { ROUTE_NAMES } from "./names";

// pages
import StripeCardPayment from "views/StripePayment/CardPayment";
import StripeIdealPayment from "views/StripePayment/IdealPayment";
import StripeSocialPayment from "views/StripePayment/SocialPayment";
import TikkiePaymentVerification from "views/TikkiePaymentVerification";

// components
import { GlobalFooter, Loader } from "components";

const SuspenseFallback = () => (
  <div className="ticket-view-loading">
    <Loader />
  </div>
);

const withSuspenseFallback = (WrappedComponent) => {
  return (props) => (
    <Suspense fallback={<SuspenseFallback />}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};

const withGlobalFooter = (WrappedComponent) => {
  return (props) => (
    <>
      <WrappedComponent {...props} />
      <GlobalFooter />
    </>
  );
};

// Pages
const TicketLoading = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/TicketLoading")))
);

const TicketBooking = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/TicketBooking")))
);

const StripeCardPaymentWithFooter = withGlobalFooter(StripeCardPayment);

const StripeIdealPaymentWithFooter = withGlobalFooter(StripeIdealPayment);

const StripeSocialPaymentWithFooter = withGlobalFooter(StripeSocialPayment);

const TikkiePaymentVerificationWithFooter = withGlobalFooter(
  TikkiePaymentVerification
);

const StripePaymentCheck = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/StripePayment/CheckPayment")))
);

const StripePaymentComplete = withGlobalFooter(
  withSuspenseFallback(
    lazy(() => import("views/StripePayment/PaymentComplete"))
  )
);

const OrderConfirmation = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/OrderConfirmation")))
);

const AddToMyCalender = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/AddToMyCalender")))
);

const ManageBooking = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/ManageBooking")))
);

const TicketScan = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/TicketScan")))
);

const GetTicket = withGlobalFooter(
  withSuspenseFallback(lazy(() => import("views/GetTicket")))
);

const router = createBrowserRouter([
  {
    path: ROUTE_NAMES.LANDING,
    element: <TicketLoading />,
  },
  {
    path: ROUTE_NAMES.BOOKING,
    element: <TicketBooking />,
  },
  {
    path: ROUTE_NAMES.CARD_PAYMENT,
    element: <StripeCardPaymentWithFooter />,
  },
  {
    path: ROUTE_NAMES.IDEAL_PAYMENT,
    element: <StripeIdealPaymentWithFooter />,
  },
  {
    path: ROUTE_NAMES.SOCIAL_PAYMENT,
    element: <StripeSocialPaymentWithFooter />,
  },
  {
    path: ROUTE_NAMES.PAYMENT_CHECK,
    element: <StripePaymentCheck />,
  },
  {
    path: ROUTE_NAMES.PAYMENT_COMPLETE,
    element: <StripePaymentComplete />,
  },
  {
    path: ROUTE_NAMES.TIKKIE_PAY_CHECK,
    element: <TikkiePaymentVerificationWithFooter />,
  },
  {
    path: ROUTE_NAMES.ORDER_CONFIRMATION,
    element: <OrderConfirmation />,
  },
  {
    path: ROUTE_NAMES.MANAGE_BOOKING,
    element: <ManageBooking />,
  },
  {
    path: ROUTE_NAMES.SCAN_TICKET,
    element: <TicketScan />,
  },
  {
    path: ROUTE_NAMES.GET_TICKET,
    element: <GetTicket />,
  },
]);

export default router;
