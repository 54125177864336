import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../constants";

const ticketServices = createApi({
  reducerPath: "ticket",
  tagType: "ticket-booking",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.token;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {
    return {
      getTicketsInfo: builder.query({
        query: (id) => {
          return {
            url: `/users/validateBookingViaClassLink/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),
      getPaymentProfile: builder.query({
        query: (id) => {
          return {
            url: `/bookings/payments/getAvailablePaymentMethods?teacherID=${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),

      getBookingConfirmation: builder.query({
        query: (id) => {
          return {
            url: `/bookings/getMybookings/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),

      getValidateWebLink: builder.query({
        query: (id) => {
          return {
            url: `evnts/public/validateWebLink/${id}`,
            method: "GET",
          };
        },
        providesTags: ["ticket-booking"],
      }),

      getAvailableSlots: builder.query({
        query: (data) => {
          return {
            url: `/service/availableSlots`,
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      getValidateManageBooking: builder.query({
        query: (id) => {
          return {
            url: `/bookings/validateManageBooking`,
            method: "POST",
            body: JSON.stringify({ manageBookingToken: id }),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
        providesTags: ["ticket-booking"],
      }),

      rescheduleBooking: builder.mutation({
        query: (data) => {
          return {
            url: `/bookings/rescheduleServiceBookings`,
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      cancelAppointment: builder.mutation({
        query: (data) => {
          return {
            url: `bookings/cancelServiceBooking`,
            method: "DELETE",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          };
        },
      }),

      getEventDetails: builder.query({
        query: ({ name, refId }) =>
          `evnts/schedule/get?eventName=${name}&referenceId=${refId}`,
      }),

      getBookingInfo: builder.query({
        query: (bookingId) => `bookings/getBookingDetails/${bookingId}`,
      }),

      getManageInfo: builder.query({
        query: (manageBookingToken) =>
          `bookings/getMybookings?manageBookingToken=${manageBookingToken}`,
      }),
    };
  },
});

export const {
  useGetTicketsInfoQuery,
  useGetPaymentProfileQuery,
  useGetBookingConfirmationQuery,
  useGetValidateWebLinkQuery,
  useGetValidateManageBookingQuery,
  useRescheduleBookingMutation,
  useCancelAppointmentMutation,
  useLazyGetAvailableSlotsQuery,
  useGetEventDetailsQuery,
  useGetBookingInfoQuery,
  useGetManageInfoQuery,
} = ticketServices;

export default ticketServices;
